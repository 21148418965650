import { render, staticRenderFns } from "./EdicaoDeUsuario.vue?vue&type=template&id=81281e40&scoped=true&"
import script from "./EdicaoDeUsuario.vue?vue&type=script&lang=ts&"
export * from "./EdicaoDeUsuario.vue?vue&type=script&lang=ts&"
import style0 from "./EdicaoDeUsuario.vue?vue&type=style&index=0&id=81281e40&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81281e40",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAvatar,VBtn,VCheckbox,VCol,VContainer,VForm,VIcon,VImg,VRow,VSubheader,VTextField})



























import { Vue, Component, Watch } from 'vue-property-decorator'
import BuscaDeGrupoEconomico from './BuscaDeGrupoEconomico.vue'
import { GrupoEconomico } from '@/models/GrupoEconomico'

@Component({
	components: {
		BuscaDeGrupoEconomico,
	},
})
export default class DialogoDeBuscaDeGrupoEconomico extends Vue {
	grupoEconomico: GrupoEconomico | null = null
	mostra = false

	@Watch('grupoEconomico')
	onChangeGrupoEconomico(grupoEconomico: GrupoEconomico | null){
		if (!grupoEconomico) return
		this.$emit('input', grupoEconomico)
		this.mostra = false
		this.$nextTick(() => {
			this.grupoEconomico = null
		})
	}
}


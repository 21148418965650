
































































































import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator'
import { Loja, Pageable } from '@/models'
import { Fragment } from 'vue-fragment'
import { VListItemContent } from 'vuetify/lib'
import axios, { CancelTokenSource } from 'axios'
import { FindLojaUseCase } from '@/usecases'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'

@Component({
	components: {
		Fragment,
		VListItemContent,
	},
})
export default class DialogoSeletorDeLojas extends Vue  {
	@PropSync('value') selecionados!: Loja[] | null
	@Prop({ type: Function }) to?: (loja: Loja) => void
	@Prop() encurtado!: boolean
	loja: Loja | null = null
	mostra = false
	cancelToken: CancelTokenSource | null = null
	carregando = false
	busca = ''
	lojas: Loja[] = []
	lojasSelecionadasNaTela: string[] = []
	page: Pageable = {
		page: 1,
		size: 5,
		sort: [],
	}
	totalPages = 1
	lojasSelecionadas: Loja[] = []
	mudouPagina = false

	findLojaUseCase = new FindLojaUseCase()

	@Watch('loja')
	onChangeLoja(loja: Loja | null){
		if (!loja) return
		this.$emit('input', loja)
		this.mostra = false
		this.$nextTick(() => {
			this.loja = null
		})
	}

	@Watch('busca')
	async onChangeBusca() {
		this.lojas = []
		this.page.page = 1
		this.lojasSelecionadas = []
		const lojasDaPagina = await this.findLojas()
		this.atualizarSelecaoItensDaTela(lojasDaPagina)
	}

	@Watch('page', { deep: true, immediate: true })
	async onChangePageable() {
		this.mudouPagina = true
		this.lojas = []
		const lojasDaPagina = await this.findLojas()
		this.atualizarSelecaoItensDaTela(lojasDaPagina)
	}

	atualizarSelecaoItensDaTela(lojasDaPagina: Loja[] | undefined) {
		const lojasSelecionadasDaPagina = lojasDaPagina ?
			this.lojasSelecionadas.filter(lojaSelecionada => lojasDaPagina.map(lojaDaPagina => lojaDaPagina.id).includes(lojaSelecionada.id))
			:
			[]

		if (lojasDaPagina) {
			this.lojasSelecionadasNaTela = lojasSelecionadasDaPagina.map(loja => loja.id)
		}
	}

	@Watch('lojasSelecionadasNaTela')
	onChangeSelecionados(selecaoAtual: string[], selecaoAnterior: string[]) {
		if (selecaoAtual && !this.mudouPagina) {
			if(selecaoAtual.length > selecaoAnterior.length) {
				const lojasAdicionadas = selecaoAtual.filter(x => !selecaoAnterior.includes(x))
				lojasAdicionadas.forEach(lojaAdicionada => {
					if (!this.lojasSelecionadas.map(loja => loja.id).includes(lojaAdicionada)) {
						const lojaIndex = this.lojas.findIndex(loja => loja.id === lojaAdicionada)
						if (lojaIndex >= 0) this.lojasSelecionadas.push(this.lojas[lojaIndex])
					}
				})
			}

			if(selecaoAnterior.length > selecaoAtual.length) {
				const lojasRemovidas = selecaoAnterior.filter(x => !selecaoAtual.includes(x))
				lojasRemovidas.forEach(lojaRemovida => {
					this.lojasSelecionadas = this.lojasSelecionadas.filter(lojaSelecionada => lojaSelecionada.id !== lojaRemovida)
				})
			}
		}

		if (this.mudouPagina) this.mudouPagina = false
	}

	@Watch('mostra')
	onChangeMostra() {
		if (this.mostra) this.findLojas()
	}

	async created() {
		await this.findLojas()
	}

	mounted() {
		this.mudouPagina = false
	}

	async findLojas() {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.carregando = true

			this.cancelToken = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			const lojasPage = await this.findLojaUseCase.find({
				page: this.page.page ? this.page.page - 1 : 0,
				size: 5,
				busca: this.busca || undefined,
			}, axiosConfig )


			this.totalPages = lojasPage.totalPages
			this.lojas = lojasPage.content
			this.carregando = false

			return lojasPage.content
		} catch (e) {
			if (axios.isCancel(e)) return
			AlertModule.setError(e)
		} finally {
			this.carregando = false
		}
	}

	async marcarTudo() {
		this.buscarTodasAsLojasPorFiltro()
	}

	async buscarTodasAsLojasPorFiltro() {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.totalPages = 1
			this.carregando = true

			this.cancelToken = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			const lojasEncontradas = await this.findLojaUseCase.buscarTodasAsLojasPorFiltro({
				busca: this.busca || undefined,
			}, axiosConfig )


			this.lojas = lojasEncontradas
			this.carregando = false

			this.lojasSelecionadas = lojasEncontradas
			this.atualizarSelecaoItensDaTela(lojasEncontradas)
		} catch (e) {
			if (axios.isCancel(e)) return
			AlertModule.setError(e)
		} finally {
			this.carregando = false
		}
	}

	fechar() {
		this.mostra = false
		this.lojas = []
		this.page.page = 1
		this.lojasSelecionadas = []
		this.lojasSelecionadasNaTela = []
	}

	async confirmar() {
		this.$emit('setarLojasDoUsuario', this.lojasSelecionadas)
		this.mostra = false
	}
}







































































import { Vue, Component, PropSync, Prop } from 'vue-property-decorator'
import { Loja } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import DialogoDeBuscaDeLoja from '@/components/loja/DialogoDeBuscaDeLoja.vue'
import { UsuariosModule } from '@/store/vuex/usuario/UsuariosStore'
import DialogoSeletorDeLojas from '../loja/DialogoSeletorDeLojas.vue'

@Component({
	components: {
		DialogoDeBuscaDeLoja,
		DialogoSeletorDeLojas,
	},
})
export default class ListaDeLojas extends Vue  {
	@PropSync('value') lojas!: Loja[]
	@Prop({ type: Boolean, default: true }) seletorIndividual!: boolean
	@Prop({ type: Boolean, default: true })

	UsuariosModule = UsuariosModule

	remover(indice: number) {
		this.lojas.splice(indice, 1)
	}

	inserirLojaDoUsuario(loja: Loja) {
		const jaEstaNaInclusa = this.lojas.some(
			({ id }) => loja.id === id,
		)
		if (jaEstaNaInclusa) {
			AlertModule.setError(`${loja.nome} já consta na lista de lojas`)
			return
		}
		this.$emit('input', [
			...this.lojas,
			loja,
		])
	}

	setarLojasDoUsuario(lojasSelecionadas: Loja[]) {
		lojasSelecionadas = lojasSelecionadas.filter(lojaSelecionada => !this.lojas.map(loja => loja.id).includes(lojaSelecionada.id))

		this.$emit('input', [
			...this.lojas,
			...lojasSelecionadas,
		])
	}

	limparSelecao() {
		this.$emit('input', [])
	}
}





















import { Ref, Component, Vue } from 'vue-property-decorator'
import { mask } from 'vue-the-mask'
import { cpfInvalido } from '@/shareds/regras-de-form'

@Component({
	directives: {
		mask,
	},
})
export default class CampoDeCpf extends Vue  {
	@Ref() campo!: HTMLInputElement
	cpfInvalido = cpfInvalido

	focus() {
		this.campo.focus()
	}
}

































































































































































import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import AppLoading from '@/components/ui/AppLoading.vue'
import ListaDeLojas from '@/components/usuario/ListaDeLojas.vue'
import RevealPasswordField from '@/components/ui/RevealPasswordField.vue'
import CampoDeTelefone from '@/components/ui/CampoDeTelefone.vue'
import {
	SaveUsuarioUseCase,
	FindUsuarioUseCase,
	FindLojaUseCase,
	FindPerfilUseCase,
} from '@/usecases'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { UsuariosModule } from '@/store/vuex/usuario/UsuariosStore'
import { Loja, PontoDeVendaDoUsuario, Usuario } from '@/models'
import SelecaoDePerfil from '@/components/perfil/SelecaoDePerfil.vue'
import EdicaoGenerica from '@/components/layout/EdicaoGenerica.vue'
import { obrigatorio, naoPermiteEmail } from '@/shareds/regras-de-form'
import ListaDeLojasEPdvs from '@/components/usuario/ListaDeLojasEPdvs.vue'
import ListaDeGruposEconomicos from '@/components/usuario/ListaDeGruposEconomicos.vue'
import { nextTick } from '@/shareds/utils'
import CampoDeCpf from '@/components/ui/CampoDeCpf.vue'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { criarUsuario } from '@/shareds/usuario-shareds'

@Component({
	components: {
		AppLoading,
		ListaDeLojas,
		RevealPasswordField,
		SelecaoDePerfil,
		EdicaoGenerica,
		ListaDeLojasEPdvs,
		CampoDeCpf,
		CampoDeTelefone,
		ListaDeGruposEconomicos,
	},
})
export default class EdicaoDeUsuario extends Vue {
	@Ref() form1!: HTMLFormElement
	@Ref() edicaoGenerica!: EdicaoGenerica<Usuario>
	@Prop({ type: Boolean }) novo!: boolean
	@Prop({ type: String }) id!: string
	@Ref() camposDeTelefones!: CampoDeTelefone[]

	obrigatorio = obrigatorio
	naoPermiteEmail = naoPermiteEmail
	erro: null | Error = null
	usuario: Usuario | null = null
	versaoAnterior: Usuario | null = null
	carregando = true
	salvando = false


	saveUsuarioUseCase = new SaveUsuarioUseCase()
	findUsuarioUseCase = new FindUsuarioUseCase()
	findLojaUseCase = new FindLojaUseCase()
	findPerfilUseCase = new FindPerfilUseCase()
	lojas: Loja[] = []
	pdvs: PontoDeVendaDoUsuario[] = []

	@Watch('id', { immediate: true })
	onChangeId() {
		this.carregar()
	}

	get permiteInativarFuncionarios() {
		return UserLoginStore.permiteRegraDeNegocio('pode-inativar-funcionario')
	}

	async carregar() {

		try {
			this.carregando = true
			this.lojas = []
			this.pdvs = []
			const usuario = this.novo
				? criarUsuario()
				: await this.findUsuarioUseCase.findById(this.id)
			this.lojas = usuario.lojas
			this.pdvs = usuario.pdvs
			this.usuario = usuario
			nextTick().then(() => {
				this.versaoAnterior = JSON.parse(JSON.stringify(this.usuario))
			})
			UsuariosModule.setUsuarioSelecionado(this.usuario)
		} catch (error: any) {
			this.erro = error
			AlertModule.setError(error)
		} finally {
			this.$nextTick(() => {
				this.carregando = false
			})
		}
	}

	adicionarTelefone() {
		if (!this.usuario) return
		this.usuario.telefones.push('')
		this.$nextTick(() => {
			if (!this.usuario) return
			const campo = this.camposDeTelefones[this.usuario.telefones.length - 1]
			campo.$children[0].focus()
		})
	}

	criarUsuario(): Usuario {
		return {
			id: '',
			email: null,
			nome: '',
			login: '',
			cpf: null,
			ativo: true,
			perfil: {
				id: '',
				nome: '',
				permissoes: [],
				gruposEconomicos: [],
				percentualMaximoDesconto: 0,
				tempoParaLogoff: '',
				percentualMaximoDescontoPorItem: 0,
				tempoLimiteDeInatividade: '',
			},
			tags: [],
			lojas: [],
			avatar: '',
			senha: '',
			pdvs: [],
			telefones: [],
			vendedor: false,
			criaFaq: false,
			gruposEconomicos: [],
			autoLoginAoAbrirCaixa: false,
			codigoExterno: '',
		}
	}

	async salvar() {
		if (!this.usuario) return
		if (!this.form1.validate()) return
		if (this.usuario.gruposEconomicos.length === 0) {
			AlertModule.setError('Informe ao menos um grupo econômico')
			return
		}
		try {
			this.salvando = true
			const usuario: Usuario = {
				...this.usuario,
				senha: !this.usuario.senha ? '' : this.usuario.senha,
				lojas: [...this.lojas],
				pdvs: !this.pdvs ? [] : [...this.pdvs],
				cpf: this.usuario.cpf || null,
				email: this.usuario.email || null,
				vendedor: this.usuario.vendedor || false,
				criaFaq: this.usuario.criaFaq || false,
				gruposEconomicos: this.usuario.gruposEconomicos,
				autoLoginAoAbrirCaixa: this.usuario.autoLoginAoAbrirCaixa || false,
				codigoExterno: this.usuario.codigoExterno || undefined,
			}
			const usuarioSalvo = await this.saveUsuarioUseCase.save(usuario)
			this.id
				? UsuariosModule.atualizaUsuario(usuarioSalvo)
				: UsuariosModule.adicionaUsuario(usuarioSalvo)

			AlertModule.setSuccess('Usuário salvo com sucesso!')
			if (this.novo) {
				this.$router.push({
					name: 'Usuário',
					params: { id: usuarioSalvo.id },
				})
			}
			this.versaoAnterior = JSON.parse(JSON.stringify(this.usuario))
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
	}

	get getMessageError() {
		return AlertModule.getErrorMsg
	}
	
	async beforeRouteUpdate(to,from,next){
		next(!await this.edicaoGenerica.validarTransicao())
	}

	async beforeRouteLeave(to, from, next){
		if(!this.salvando){
			next(!await this.edicaoGenerica.validarTransicao())
		}else {
			next()
		}
	}

	@Watch('usuario.ativo', { immediate: true })
	onChangeUsuarioAtivo(ativo: boolean) {
		if (this.usuario && !ativo) {
			this.usuario.vendedor = false
			this.usuario.autoLoginAoAbrirCaixa = false
		}
	}
}

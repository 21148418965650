








































import { Vue, Component, PropSync } from 'vue-property-decorator'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { UsuariosModule } from '@/store/vuex/usuario/UsuariosStore'
import { GrupoEconomico } from '@/models/GrupoEconomico'
import DialogoDeBuscaDeGrupoEconomico from '../loja/DialogoDeBuscaDeGrupoEconomico.vue'

@Component({
	components: {
		DialogoDeBuscaDeGrupoEconomico,
	},
})
export default class ListaDeGruposEconomicos extends Vue  {
	@PropSync('value') gruposEconomicos!: GrupoEconomico[]

	UsuariosModule = UsuariosModule

	remover(indice: number) {
		this.gruposEconomicos.splice(indice, 1)
	}

	inserirGrupoEconomicoDoUsuario(grupoEconomico: GrupoEconomico) {
		const jaEstaIncluso = this.gruposEconomicos.some(
			({ id }) => grupoEconomico.id === id,
		)
		if (jaEstaIncluso) {
			AlertModule.setError(`${grupoEconomico.descricao} já consta para o usuário`)
			return
		}
		this.$emit('input', [
			...this.gruposEconomicos,
			grupoEconomico,
		])
	}
}


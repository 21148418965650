














































import { Vue, Component, PropSync } from 'vue-property-decorator'
import { PontoDeVendaDoUsuario } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import DialogoDeAssociacaoDeLojasPdvs from './DialogoDeAssociacaoDeLojasPdvs.vue'
import { UsuariosModule } from '@/store/vuex/usuario/UsuariosStore'

@Component({
	components: {
		DialogoDeAssociacaoDeLojasPdvs,
	},
})
export default class ListaDeLojasEPdvs extends Vue {
	@PropSync('value') pdvs!: PontoDeVendaDoUsuario[]

	UsuariosModule = UsuariosModule
	mostrarDialogoDeAssociacao = false

	associarPdvsAoUsuario(pdvsDoUsuario: PontoDeVendaDoUsuario[]) {
		const pdvEncontrado = this.pdvs.filter(pdv =>
			pdvsDoUsuario.some(({ id }) => id === pdv.id),
		)
		if (pdvEncontrado && pdvEncontrado.length > 0) {
			AlertModule.setError(`O ponto de venda ${pdvEncontrado[0].nome} da loja ${pdvEncontrado[0].loja.nome} já consta neste usuário`)
			this.mostrarDialogoDeAssociacao = true
			return
		}
		this.mostrarDialogoDeAssociacao = false
		this.$emit('input', [ ...this.pdvs, ...pdvsDoUsuario ])
	}

	remover(indice: number) {
		this.pdvs.splice(indice, 1)
	}

	get pontosDeVendaOrdenados() {
		return this.pdvs 
			? [...this.pdvs].sort(
				(pdvA, pdvB) => pdvA.nome.localeCompare(pdvB.nome),
			)
			: []
	}
}






















































import { Vue, Component, Watch, PropSync, Prop } from 'vue-property-decorator'
import SeletorDeLojas from '@/components/loja/SeletorDeLojas.vue'
import { Loja, PontoDeVenda, PontoDeVendaDoUsuario } from '@/models'

@Component({
	components: {
		SeletorDeLojas,
	},
})
export default class DialogoDeAssociacaoDeLojasPdvs extends Vue {
	@PropSync('mostra') syncMostra!: boolean
	@Prop({ type: Array, default: () => [] }) pdvsExistentes!: PontoDeVendaDoUsuario[]

	loja: Loja | null = null
	pontosDeVenda: PontoDeVenda[] = []

	@Watch('loja')
	onChangeLoja(loja: Loja | null, lojaAnterior: Loja | null) {
		if (!loja || loja !== lojaAnterior) this.pontosDeVenda = []
	}

	get pontosDeVendaDisponiveis() {
		return this.loja
			? this.loja.pdvs.filter(pdv => this.pdvsExistentes.every(({ id }) => id !== pdv.id))
			: []
	}

	salvar() {
		const pdvsDoUsuario = this.pontosDeVenda.map(pdv => {
			return {
				id: pdv.id,
				nome: pdv.nome,
				loja: this.pontosDeVenda[0].loja,
			}
		})
		this.$emit('associar', pdvsDoUsuario)
		this.pontosDeVenda = []
		this.loja = null
	}
}
